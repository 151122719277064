import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Engineering1 from "../assets/images/Engineering/1.webp";
import Engineering2 from "../assets/images/Engineering/2.webp";
import Engineering3 from "../assets/images/Engineering/3.webp";
import Engineering4 from "../assets/images/Engineering/4.webp";
import Engineering5 from "../assets/images/Engineering/5.webp";
import Engineering6 from "../assets/images/Engineering/6.webp";
import Engineering7 from "../assets/images/Engineering/7.webp";
import Engineering8 from "../assets/images/Engineering/8.webp";
import Engineering9 from "../assets/images/Engineering/9.webp";
import Engineering10 from "../assets/images/Engineering/10.webp";
import Engineering11 from "../assets/images/Engineering/11.webp";
import Footer1 from "../assets/images/Home/7.webp";




const Engineering = () => (
    <Layout>
        <SEO title="Engineering" />
        <Navbar />
        <div className="page-title-area">
            <img className="imageStyle" src={Engineering1} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering2} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering3} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering4} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering5} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering6} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering7} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering8} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering9} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering10} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Engineering11} alt="image" />
        </div>
        <div className="page-normal-area">
            <img className="imageStyle" src={Footer1} alt="image" />
        </div>
    </Layout>
)

export default Engineering;
